import React, { useState } from "react";
import classNames from "classnames";
import { m, AnimatePresence } from "framer-motion";
import { PersonCard } from "@molecules";
import { Container, Text, ButtonAlt, AnimateIn } from "@atoms";

const PeopleGrid = React.memo(
  ({
    className: _className,
    heading,
    initial,
    paddingBottom,
    paddingTop,
    people,
  }) => {
    const [limit, setLimit] = useState(initial);
    const initialPeople = initial ? people?.slice(0, limit) : people;

    return (
      <section
        className={classNames(
          {
            "pt-20 md:pt-28": paddingTop,
            "pb-20 md:pb-28": paddingBottom,
          },
          _className
        )}
      >
        <Container>
          {heading && (
            <Text variant="h3" className="mb-12 md:mb-16">
              {heading}
            </Text>
          )}
          <AnimateIn preset="fadeUpFast" threshold="some">
            <div className="flex flex-wrap gap-8">
              <AnimatePresence mode="wait">
                {initialPeople?.map(p => {
                  return (
                    <m.div
                      key={p.uid}
                      initial={{ opacity: 0, y: 100 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { type: "tween", transition: 0.3 },
                      }}
                      exit={{
                        opacity: 0,
                        y: 100,
                        transition: { type: "tween", transition: 0.3 },
                      }}
                      className="w-full sm:flex-[0_1_calc(50%-1rem)] md:flex-[0_1_calc(33.333%-1.333rem)]"
                    >
                      <PersonCard {...p} />
                    </m.div>
                  );
                })}
              </AnimatePresence>
            </div>
          </AnimateIn>
          {!!initial && limit < people?.length && (
            <div className="flex justify-center pt-12 md:pt-28">
              <ButtonAlt onClick={() => setLimit(people?.length)} down>
                Load More
              </ButtonAlt>
            </div>
          )}
        </Container>
      </section>
    );
  }
);

PeopleGrid.defaultProps = {
  paddingTop: false,
  initial: null,
};

export default PeopleGrid;
